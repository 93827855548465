import { useEffect } from 'react'
import { v4 as uuid } from 'uuid'
import { Route, Routes, Navigate, useLocation } from 'react-router-dom'
import { tracking } from '@/lib/tracking'
import { NewOrderRoute } from './new-order'
import { OrderConfirmationRoute } from './order-confirmation'
import { OrderRoutes } from './order'

const RedirectToOrder = () => {
  // Generate a random uuid reservations can be connected to
  return <Navigate to={`/new/${uuid()}`} replace />
}

export const RootRoutes = () => {
  const location = useLocation()

  useEffect(() => {
    tracking.pageView()
  }, [location])

  return (
    <Routes>
      <Route path="/" element={<RedirectToOrder />} />
      <Route path="/new/:orderId/*" element={<NewOrderRoute />} />
      <Route path="/new" element={<RedirectToOrder />} />
      <Route
        path="/order-confirmation/:signature/:orderId"
        element={<OrderConfirmationRoute />}
      />
      <Route path="/orders/:signature/:orderId/*" element={<OrderRoutes />} />
      <Route path="*" element={<div>404</div>} />
    </Routes>
  )
}
