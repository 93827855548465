import { config } from '@/config'

declare global {
  interface Window {
    gtag?: any
    dataLayer?: any
    fbq?: any
    _fbq?: any
  }
}

class Tracking {
  private gaTag: string | null
  private metaTag: string | null

  constructor({ gaTag, metaTag }: { gaTag: string; metaTag: string }) {
    this.gaTag = gaTag
    this.metaTag = metaTag
  }

  init() {
    if (this.gaTag) {
      this.initGa()
    }
    if (this.metaTag) {
      this.initMeta()
    }
  }

  pageView() {
    if (window.gtag) {
      window.gtag('config', this.gaTag, {
        page_path: window.location.pathname,
      })
    }
    if (window.fbq) {
      window.fbq('track', 'PageView')
    }
  }

  initGa() {
    if (window.gtag) return
    const script = document.createElement('script')
    script.src = `https://www.googletagmanager.com/gtag/js?id=${this.gaTag}`
    script.async = true
    document.body.appendChild(script)
    window.dataLayer = window.dataLayer || []
    function gtag(...args: any[]) {
      window.dataLayer?.push(...args)
    }
    window.gtag = gtag
    gtag('js', new Date())
    gtag('config', this.gaTag, {
      page_path: window.location.pathname,
    })
  }

  initMeta() {
    if (window.fbq) return
    window.fbq = (...args: any) => {
      if (window.fbq.callMethod) {
        window.fbq.callMethod(...args)
      } else {
        window.fbq.queue.push(args)
      }
    }
    if (!window._fbq) {
      window._fbq = window.fbq
    }
    window.fbq.loaded = true
    window.fbq.version = '2.0'
    window.fbq.queue = []
    const script = document.createElement('script')
    script.async = true
    script.src = 'https://connect.facebook.net/en_US/fbevents.js'
    const firstScript = document.getElementsByTagName('script')[0]
    firstScript.parentNode?.insertBefore(script, firstScript)
    window.fbq('init', this.metaTag)
    window.fbq('track', 'PageView')
  }
}

export const tracking = new Tracking({
  gaTag: config.gaTag,
  metaTag: config.metaTag,
})
