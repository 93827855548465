import { useState } from 'react'
import { cn } from '@/utils/cn'
import { Button } from './ui/button'

type CookieBannerProps = {
  onAccept: () => void
}

export const CookieBanner = ({ onAccept }: CookieBannerProps) => {
  const [show, setShow] = useState(!localStorage.getItem('cookieConsent'))

  const classNames = cn(
    '"z-[999] bg-[#eff1f1] border-t border-t-[#adb9ba] py-6 px-8 hidden fixed top-[auto] right-0 left-0 bottom-0 left-0"',
    show ? 'flex' : 'hidden'
  )

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'true')
    setShow(false)
    onAccept()
  }

  const handleDecline = () => {
    localStorage.setItem('cookieConsent', 'false')
    setShow(false)
  }

  return (
    <div className={classNames}>
      <div className="flex flex-col md:flex-row items-center w-full max-w-[62.5rem] mx-auto">
        <div className="text-[#000f1f] mr-6 text-base">
          Denne nettsiden bruker informasjonskapsler for å forbedre
          brukerautentifisering, navigering og andre funksjoner for å forbedre
          brukeropplevelse. Ved å bruke denne nettsiden aksepterer du at vi
          lagrer cookies på din datamaskin. Ved å klikke på 'Godta' vil du
          akseptere cookies.
        </div>
        <div className="flex flex-none flex-row justify-start items-center gap-3">
          <Button variant="secondary" tabIndex={0} onClick={handleDecline}>
            Avslå
          </Button>
          <Button tabIndex={0} onClick={handleAccept}>
            Godta
          </Button>
        </div>
      </div>
    </div>
  )
}
