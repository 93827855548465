import { useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'urql'
import { createUrqlClient } from '@/lib/urql-client'
import { tracking } from './lib/tracking'
import useVersionCheck from '@/hooks/use-version-check'
import { Toaster } from '@/components/ui/toaster'
import { ScrollToTop } from '@/components/scroll-to-top'
import { ErrorBoundary } from '@/components/error-boundary'
import { OutdatedVersionNotification } from '@/components/outdated-version-notification'
import { CookieBanner } from '@/components/cookie-banner'
import { RootRoutes } from '@/routes'
import '@/index.css'

export const App = () => {
  const { customFetch, hasNewVersion } = useVersionCheck()
  const [urqlClient] = useState(() => createUrqlClient({ fetch: customFetch }))
  const [cookieConsent, setCookieConsent] = useState(
    localStorage.getItem('cookieConsent') === 'true'
  )

  useEffect(() => {
    if (cookieConsent) {
      tracking.init()
    }
  }, [cookieConsent])

  return (
    <ErrorBoundary>
      <Provider value={urqlClient}>
        <Toaster />
        <OutdatedVersionNotification open={hasNewVersion} />
        <BrowserRouter>
          <ScrollToTop />
          <RootRoutes />
        </BrowserRouter>
      </Provider>
      <CookieBanner onAccept={() => setCookieConsent(true)} />
    </ErrorBoundary>
  )
}
